import React from 'react';
import { Card } from 'react-bootstrap';
import { card_col, card_row, services_section, service_card } from './ServicesShort.module.scss';

const ProcessNew: React.FC = () => {
    return (
        <div className={services_section} data-testid="services-section">
            <div className="services-container" data-testid="services-container">
                <h2 className="center">Our Services</h2>
                <div className="container">
                    <div className={`row ${card_row}`}>
                        <div className={`col-lg-3 col-md-6 mb-4 ${card_col}`}>
                            <Card data-testid="card-1" className={`${service_card} p-2 h-100`}>
                                <a href="/managed-it">
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-managed.png"
                                        alt="managed IT in the cloud"
                                    />

                                    <Card.Body>
                                        <Card.Title>Managed IT Services</Card.Title>
                                    </Card.Body>
                                </a>
                            </Card>
                        </div>
                        <div className={`col-lg-3 col-md-6 mb-4 ${card_col}`}>
                            <a href="/devops">
                                <Card data-testid="card-2" className={`${service_card} p-2 h-100`}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-devops.png"
                                        alt="devops and cloud"
                                    />

                                    <Card.Body>
                                        <Card.Title>DevOps &amp; Cloud</Card.Title>
                                    </Card.Body>
                                </Card>{' '}
                            </a>
                        </div>
                        <div className={`col-lg-3 col-md-6 mb-4 clear-fix ${card_col}`}>
                            <a href="custom-software">
                                <Card data-testid="card-3" className={`${service_card} p-2 h-100`}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-software.png"
                                        alt="developing software"
                                    />
                                    <Card.Body>
                                        <Card.Title>Custom Software</Card.Title>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                        <div className={`col-lg-3 col-md-6 mb-4 ${card_col}`}>
                            <a href="/web-development">
                                <Card data-testid="card-4" className={`${service_card} p-2 h-100`}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-web.png"
                                        alt="website design"
                                    />
                                    <Card.Body>
                                        <Card.Title>Custom Website Development</Card.Title>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessNew;
