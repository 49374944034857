import { default as React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';
import Services from '../compontents/ServicesShort/ServicesShort';
import { discount_header, it_30_bg } from './discount.module.scss';

type DiscountPageProps = {
    location: any;
};

type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;

const DiscountPage = ({ location }: DiscountPageProps) => {
    const TARGET_ID = 'hubspot-target';

    useEffect(() => {
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: '90e7f310-c51b-48e4-b853-b59d8c639ca2',
                    target: `#${TARGET_ID}`,
                });
            };
        }
    }, []);

    return (
        <Layout location={location}>
            <Helmet>
                <title>Discount</title>
            </Helmet>
            {/* header */}
            <div className={discount_header} data-testid="discount-container">
                <div className="col-md-12 d-none d-md-block center">
                    <img className="img-fluid" src="../../images/discount-landing/discount-header-med.png"></img>
                </div>
                <div className="d-md-none center">
                    <img className="img-fluid" src="../../images/discount-landing/discount-header-small.png"></img>
                </div>
            </div>
            {/* end header */}

            <div className="container my-5">
                <h1 className="center mt-5 mb-0">Offer only good for a limited time</h1>
                <p className="center" style={{ fontStyle: 'italic' }}>
                    Expires March 31
                </p>
                <h2 className="center mb-0">Save 30% on your first year of managed IT</h2>
                <p className="center mb-5" style={{ fontStyle: 'italic' }}>
                    Fill out the form below to contact us and learn more.
                </p>
                <div className={it_30_bg}>
                    <div className="row">
                        <div className="col-md-5 d-md-none" style={{ marginBottom: '-70px' }}>
                            <img className="img-fluid" src="../../images/discount-landing/30-off.png" />
                        </div>
                        <div className="col-md-7">
                            <img className="img-fluid" src="../../images/discount-landing/30-off-it-guys.png" />
                        </div>
                        <div className="col-md-5 d-none d-md-block">
                            <img className="img-fluid" src="../../images/discount-landing/30-off.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-10 mx-auto">
                        <h2 className="center">Save on any other service too!</h2>
                        <p className="center mainText">
                            For a limited time, new customers can save <strong>20%</strong> on their first contract for
                            ANY of the amazing services offered by SquareHook
                        </p>
                    </div>
                </div>
            </div>

            <Services />

            {/* Contact */}
            <div className="container pt-3">
                <h2 className="center">Contact us now to get this deal!</h2>
                <p className="center">
                    To take advantage of the new customer <strong>Digital Discount</strong>, just send us a message with
                    the form below.
                </p>
                <p className="center">
                    Still not sure if managed IT is right for you? Contact us and set up a free consultation to see how
                    it can benefit your business.
                </p>
                <p className="center">
                    <span style={{ fontWeight: 'bold' }}>Don&apos;t want to fill out a form?</span>
                    <br />
                    Just give us a call and mention the &quot;Digital Discount&quot;
                </p>
                <h3 className="center">Phone: 801-309-1959</h3>
                <div className="row">
                    <div className="col-lg-6 col-md-10 mx-auto">
                        <div id={TARGET_ID}></div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DiscountPage;
